import { getCLS, getFID, getLCP, getTTFB, getFCP, Metric } from "web-vitals";

export type WebVitalsOptions = {
  sendWebVitals: "true" | "false";
  experimentId?: string;
  variationId?: string;
};

function sendToGoogleAnalytics({ name, delta, id }: Metric) {
  const event = {
    event_category: "Web Vitals",
    value: Math.round(name === "CLS" ? delta * 1000 : delta),
    event_label: id,
    non_interaction: true,
  };
  if (process.env.NODE_ENV === "development") {
    console.log("sending web-vital", event);
  } else if (window?.gtag) {
    // eslint-disable-next-line no-undef
    window.gtag("event", name, event);
    console.log("sending web-vital", event);
  }
}

export function webVitals() {
  getCLS(sendToGoogleAnalytics);
  getFID(sendToGoogleAnalytics);
  getLCP(sendToGoogleAnalytics);
  getTTFB(sendToGoogleAnalytics);
  getFCP(sendToGoogleAnalytics);
}
