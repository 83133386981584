import { BrowserPluginArgs } from "gatsby";
import { WebVitalsOptions } from "./web-vitals";

export const sendGaExperiment = (
  args: BrowserPluginArgs,
  options: WebVitalsOptions
) => {
  const { experimentId, variationId } = options;
  if (shouldTriggerGA(experimentId, variationId)) {
    window.ga("gtm2.set", "exp", `${experimentId}.${variationId}`);
    window.ga(
      "gtm2.send",
      "event",
      "Experiment",
      "Trigger",
      `${experimentId}.${variationId}`
    );
  }
};

const shouldTriggerGA = (experimentId?: string, variationId?: string) => {
  if (
    typeof window !== "undefined" &&
    window.ga &&
    experimentId !== undefined &&
    variationId !== undefined
  ) {
    return true;
  }
  return false;
};
