exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-client-reviews-tsx": () => import("./../../../src/pages/client-reviews.tsx" /* webpackChunkName: "component---src-pages-client-reviews-tsx" */),
  "component---src-pages-contact-us-tsx": () => import("./../../../src/pages/contact-us.tsx" /* webpackChunkName: "component---src-pages-contact-us-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-resume-tsx": () => import("./../../../src/pages/resume.tsx" /* webpackChunkName: "component---src-pages-resume-tsx" */),
  "component---src-pages-tucson-dui-tsx": () => import("./../../../src/pages/tucson-dui.tsx" /* webpackChunkName: "component---src-pages-tucson-dui-tsx" */),
  "component---src-pages-university-students-tsx": () => import("./../../../src/pages/university-students.tsx" /* webpackChunkName: "component---src-pages-university-students-tsx" */),
  "component---src-templates-archive-tsx": () => import("./../../../src/templates/archive.tsx" /* webpackChunkName: "component---src-templates-archive-tsx" */),
  "component---src-templates-cases-tsx": () => import("./../../../src/templates/cases.tsx" /* webpackChunkName: "component---src-templates-cases-tsx" */),
  "component---src-templates-page-tsx": () => import("./../../../src/templates/page.tsx" /* webpackChunkName: "component---src-templates-page-tsx" */),
  "component---src-templates-post-tsx": () => import("./../../../src/templates/post.tsx" /* webpackChunkName: "component---src-templates-post-tsx" */)
}

