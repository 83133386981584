import { BrowserPluginArgs } from "gatsby";
import { sendGaExperiment } from "./ga-experiments";
import { WebVitalsOptions, webVitals } from "./web-vitals";

export const onClientEntry = (
  args: BrowserPluginArgs,
  options: WebVitalsOptions
) => {
  const { sendWebVitals } = options;
  if (sendWebVitals === "true") {
    webVitals();
  }
  sendGaExperiment(args, options);
};
